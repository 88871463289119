.featured {
	padding-top: 32px;
}

.featured-seven {
	padding: 115px 0 50px;
}

.featured-eight {
	padding: 117px 0 90px;

	.top-bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;

		img {
			width: 100%;
		}
	}
}

.saaspik-icon-box-wrapper {
	margin-bottom: 30px;

	&.style-one {
		margin-top: 16px;

		.saaspik-icon-box-icon {
			margin-bottom: 40px;
			height: 150px;
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				font-size: 22px;
				font-weight: 500;
				line-height: 30px;
				margin-bottom: 0;

				a {
					color: $color__primary;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}

	.saaspik-icon-box-icon {
		margin-bottom: 32px;
	}

	.pixsass-icon-box-content {
		.pixsass-icon-box-title {
			font-size: 20px;
			font-weight: 600;
			line-height: 30px;
			margin-bottom: 20px;
			transition: all 0.3s ease-in-out;

			a {
				color: $color__primary;

				&:hover {
					color: $color__theme;
				}
			}
		}

		p {
			margin: 0;
		}
	}

	&.style-two {
		margin-bottom: 88px;

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				font-size: 20px;
				font-weight: 600;
				line-height: 30px;
				margin-bottom: 10px;

				a {
					color: $color__primary;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}

	&.style-three {
		border-radius: 6px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
		padding: 40px 50px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 22;
		align-items: center;

		.saaspik-icon-box-icon {
			width: 70px;
			margin-right: 30px;
			margin-bottom: 0;
		}

		.pixsass-icon-box-content {
			flex: 1;

			p {
				margin: 0;
			}
		}
	}

	&.style-four {
		border-radius: 4px;
		border: 1px solid #ece4e4;
		padding: 60px 40px 32px;
		transition: all 0.3s ease-in-out;
		position: relative;
		overflow: hidden;

		.layer {
			position: absolute;
			top: -100%;
			left: 0;
			width: 100%;
			z-index: -1;
			transition: all 0.6s ease-in-out;
		}

		.saaspik-icon-box-icon {
			margin-bottom: 44px;
			height: 80px;
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				font-size: 22px;
				font-weight: 500;
				margin-bottom: 25px;

				a {
					color: $color__primary;

					&:hover {
						color: $color__theme;
					}
				}
			}

			p {
				margin-bottom: 22px;
			}

			.more-btn {
				font-size: 35px;
				color: #bdb4b4;
				display: inline-block;

				&:hover {
					color: $color__theme;
				}
			}
		}

		&:hover {
			box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);

			.layer {
				top: 0;
			}
		}
	}

	&.style-five {
		box-shadow: 0px 30px 60px 0px rgba(52, 27, 159, 0.1);
		padding: 45px 40px;
		background: #fff;

		.saaspik-icon-box-icon {
			height: 60px;
			width: 60px;
			line-height: 60px;
			text-align: center;
			border: 2px solid $color__theme-2;
			color: $color__theme-2;
			font-size: 22px;
			border-radius: 50%;
			transition: all 0.3s ease-in-out;
			margin-bottom: 42px;
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				margin-bottom: 15px;

				a {
					&:hover {
						color: $color__theme-2;
					}
				}
			}

			p {
				margin-bottom: 35px;
			}

			.more-btn {
				color: $color__primary;
				font-weight: 500;

				i {
					vertical-align: middle;
					display: inline-block;
					transition: all 0.3s ease-in-out;
				}

				&:hover {
					color: $color__theme-2;

					i {
						padding-left: 3px;
						color: $color__theme-2;
					}
				}
			}
		}

		&:hover {
			.saaspik-icon-box-icon {
				box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
				background: $color__theme-2;
				color: #fff;
			}
		}
	}

	&.style-six {
		display: flex;
		align-items: center;
		margin-bottom: 40px;
		flex-wrap: wrap;

		.saaspik-icon-box-icon {
			height: 70px;
			width: 70px;
			text-align: center;
			box-shadow: 0px 30px 40px 0px rgba(52, 27, 159, 0.1);
			padding: 15px;
			border-radius: 4px;
			transition: all 0.3s ease-in-out;
			margin-bottom: 0;
			margin-right: 30px;
			background: #fff;
		}

		.pixsass-icon-box-content {
			flex: 1;

			.pixsass-icon-box-title {
				margin-bottom: 15px;
				font-weight: 400;
				margin: 0;

				a {
					&:hover {
						color: $color__theme-2;
					}
				}
			}

			p {
				margin-bottom: 35px;
			}

			.more-btn {
				color: $color__primary;
				font-weight: 500;

				i {
					vertical-align: middle;
					display: inline-block;
					transition: all 0.3s ease-in-out;
				}

				&:hover {
					color: $color__theme-2;

					i {
						padding-left: 3px;
						color: $color__theme-2;
					}
				}
			}
		}
	}

	&.style-seven {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 90px;

		.saaspik-icon-box-icon {
			width: 60px;
			margin-right: 20px;
		}

		.saaspik-icon-box-content {
			flex: 2;

			.saaspik-icon-box-title {
				font-size: 20px;
				color: #081e39;
				font-weight: 600;
				margin-bottom: 13px;

				a {
					color: #081e39;

					&:hover {
						color: $color__theme-2;
					}
				}
			}

			p {
				color: #5e5b74;
				margin-bottom: 17px;
			}

			.read-more {
				color: #5e5b74;
				font-size: 16px;
				font-weight: 500;

				&:hover {
					color: $color__theme-2;
				}
			}
		}
	}

	&.style-eight {
		border-radius: 6px;
		background: #fff;
		box-shadow: 0px 30px 50px 0px rgba(64, 24, 47, 0.08);
		text-align: center;
		padding: 40px 35px;

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				margin-bottom: 15px;

				a {
					&:hover {
						color: $color__theme-4;
					}
				}
			}

			p {
				margin-bottom: 35px;
			}
		}
	}

	&.style-nine {
		margin-bottom: 90px;

		.saaspik-icon-box-icon {
			height: 70px;
			width: 70px;
			background: #fee4f4;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			&.color-two {
				background: #fcf5e3;
			}

			&.color-three {
				background: #efe9fe;
			}

			&.color-four {
				background: #eaf7eb;
			}

			&.color-five {
				background: #e4f8fd;
			}

			&.color-six {
				background: #ffeae7;
			}
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				margin-bottom: 10px;

				a {
					&:hover {
						color: $color__theme-4;
					}
				}
			}
		}
	}

	&.style-ten {
		padding: 50px 40px;
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 20px 60px 0px rgba(1, 51, 32, 0.1);
		text-align: center;

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				margin-bottom: 15px;
			}

			p {
				margin-bottom: 30px;
			}
		}
	}

	&.style-eleven {
		box-shadow: 0 20px 50px 0 rgba(14, 3, 52, 0.1);
		background: #fff;
		border-radius: 20px;
		padding: 55px 35px;
		transition: all 0.3s ease-in-out;

		.saaspik-icon-box-icon {
			height: 65px;
			display: flex;
			align-items: flex-end;
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				margin-bottom: 13px;
			}

			p {
				color: #615f6b;
			}
		}

		&:hover {
			background: #2c234d;

			.pixsass-icon-box-content {
				.pixsass-icon-box-title {
					color: #fff;

					&:hover {
						color: $color__theme-7;
					}

					a {
						color: #fff;

						&:hover {
							color: $color__theme-7;
						}
					}
				}

				p {
					color: #c3c0d0;
				}
			}
		}
	}

	&.style-twelve {
		padding: 40px 35px;
		border-radius: 6px;
		transition: all 0.3s ease-in-out;
		margin-bottom: 20px;

		.saaspik-icon-box-icon {
			height: 70px;
			width: 70px;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba(19, 200, 70, 0.102);

			&.color--two {
				background: #fff3dd;
			}

			&.color--three {
				background: #efe1fa;
			}

			&.color--four {
				background: #f6d9e2;
			}

			&.color--five {
				background: #dbddfa;
			}

			&.color--six {
				background: #d1f0f1;
			}
		}

		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				font-size: 20px;
				font-weight: 500;
				color: #2c234d;

				&:hover {
					color: $color__theme-8;
				}

				a {
					color: #2c234d;

					&:hover {
						color: $color__theme-8;
					}
				}
			}
		}

		&:hover {
			background: #fff;
			box-shadow: 0 30px 70px 0 rgba(5, 34, 85, 0.06);
		}
	}

	&.style-thirteen {
		background-color: #131b2e;
		padding: 40px 18px 30px;
		border-radius: 10px;
		transition: all 0.3s ease-in-out;

		.saaspik-icon-box-icon {
			height: 70px;
			width: 70px;
			background: #343e4f;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			position: relative;
			margin-bottom: 42px;

			&::before {
				content: "";
				position: absolute;
				left: -7px;
				top: -7px;
				right: -7px;
				bottom: -7px;
				border: 1px solid #343e4f;
				border-radius: 50%;
			}
		}

		.saaspik-icon-box-title {
			font-family: "Montserrat Alternates", serif;
			font-size: 20px;
			color: #fff;
			font-weight: 600;
		}

		.saaspik-icon-box-description {
			color: #a1a4ab;
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 30px;
		}

		a.view-link {
			text-align: center;
			display: inline-block;
		}

		&:hover {
			background-color: $color__theme-11;

			.saaspik-icon-box-icon {
				background: $color__black;
			}

			.saaspik-icon-box-title {
				color: $color__black;
			}

			.saaspik-icon-box-description {
				color: #42501e;
			}

			a.view-link {
				svg {
					path {
						stroke: $color__black;
					}
				}
			}
		}
	}

	&.style-fourteen {
		display: flex;
		align-items: center;
		border-radius: 20px;
		border: 1px solid rgba(255, 255, 255, 0.14);
		padding: 15px 40px 15px 15px;
		gap: 20px;
		align-items: center;
		transition: all 0.3s ease-in-out;

		@media (max-width: 480px) {
			flex-direction: column;
		}

		.saaspik-icon-box-icon {
			height: 100px;
			width: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 100px;
			margin: 0;
			border-radius: 10px;
			background: #131b2e;
			transition: all 0.3s ease-in-out;
		}

		.saaspik-icon-box-content-wrappper {
			display: flex;
			align-items: center;
			gap: 16px;
			@media (max-width: 480px) {
				flex-direction: column;
			}
		}

		.saaspik-icon-box-content {
			@media (min-width: 576px) {
				padding-right: 20px;
			}

			@media (max-width: 480px) {
				text-align: center;
			}
		}

		.saaspik-icon-box-title {
			color: #fff;
			font-family: "Montserrat Alternates";
			font-size: 20px;
			font-weight: 600;
		}

		.saaspik-icon-box-description {
			margin-bottom: 0;
			font-size: 14px;
			line-height: 24px;
		}

		&:hover {
			background-color: #131b2e;
			border-color: #131b2e;

			.saaspik-icon-box-icon {
				background: $color__theme-11;
			}
		}
	}
}

.ai-about-image {
	@media (max-width: 767px) {
		margin-bottom: 30px;
	}
}

.featured-six {
	padding: 110px 0 104px;
}

.featured-five {
	padding: 70px 0;
}

.featured-eight {
	.particale-top {
		width: 100%;
		height: 500px;
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		top: 0;
		left: 0;

		li {
			position: absolute;

			&:nth-child(1) {
				width: 31px;
				height: 31px;
				border-radius: 50%;
				left: 8%;
				top: 40%;
				background-color: rgba(255, 255, 255, 0.141);
				animation: animationFramesOne 20s infinite linear;
			}

			&:nth-child(2) {
				left: 16%;
				top: 54%;
				width: 0;
				height: 0;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 35px solid rgba(255, 255, 255, 0.141);
				animation: animationFramesLeft 20s infinite linear;
			}

			&:nth-child(3) {
				left: 11%;
				bottom: 28px;
				width: 0;
				height: 0;
				border-top: 15px solid transparent;
				border-bottom: 15px solid transparent;
				border-right: 20px solid rgba(255, 255, 255, 0.141);
				animation: animationFramesTwo 20s infinite linear;
			}

			&:nth-child(4) {
				width: 13px;
				height: 13px;
				border-radius: 50%;
				right: 12%;
				top: 44%;
				background-color: rgba(255, 255, 255, 0.141);
				animation: animationFramesFive 20s infinite linear;
			}

			&:nth-child(5) {
				right: 0;
				top: 33%;
				width: 0;
				height: 0;
				border-left: 30px solid transparent;
				border-right: 30px solid transparent;
				border-bottom: 50px solid rgba(255, 255, 255, 0.141);
				animation: animationFramesLeft 20s infinite linear;
			}

			&:nth-child(6) {
				right: 80px;
				top: 140px;
				width: 0;
				height: 0;
				border-top: 15px solid transparent;
				border-bottom: 15px solid transparent;
				border-right: 20px solid rgba(255, 255, 255, 0.141);
				animation: animationFramesOne 20s infinite linear;
			}
		}
	}
}

.featured-tax {
	padding: 32px 0 22px;
}

.featured-ten {
	padding: 115px 0 90px;

	@media (max-width: 991px) {
		padding: 75px 0 50px;
	}
}

.featured-two-service {
	.saaspik-icon-box-wrapper.style-two {
		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				a {
					color: $color__primary;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}
}

.featured-two {
	padding: 126px 0 28px;

	.section-title {
		margin-bottom: 80px;
	}
}

.featured-three {
	padding: 116px 0 120px;
	background: $background__bg;
	overflow: hidden;

	.scroll-circle {
		z-index: 1;
		left: -120px;
		bottom: -67%;
	}
}

.featured-four {
	padding-top: 400px;
}

.featured-two-same {
	padding-top: 144px;
	padding-bottom: 27px;

	.section-title {
		margin-bottom: 80px;
	}

	.saaspik-icon-box-wrapper.style-two {
		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				a {
					color: $color__primary;

					&:hover {
						color: $color__theme-2;
					}
				}
			}
		}
	}

	.scroll-circle {
		bottom: auto;
		top: -50%;
	}
}

.featured-four-ab {
	padding-top: 146px;
}

.featured-nine {
	margin-bottom: -110px;
	z-index: 22;
	padding-top: 50px;
}

.featured-homethree {
	padding-top: 228px;
	padding-bottom: 27px;

	.section-title {
		margin-bottom: 80px !important;
	}
}

.feature-twelve {
	background: #f6f7fa;
	padding: 115px 0 90px;
}

.ai-services {
	background-color: #0b1225;
	padding: 135px 0 110px;

	@media (max-width: 991px) {
		padding: 80px 0 60px;
	}
}

.down-bg {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: -1;
}

@media (max-width: 1200px) {
	.featured-homethree {
		padding-top: 118px;
	}

	.featured-six {
		padding-top: 50px;
	}
}

@media (max-width: 991px) {
	.featured-two {
		padding: 85px 0 35px;
	}

	.featured-eight {
		padding: 72px 0 50px;
	}

	.featured-two-same {
		padding-top: 74px;
		padding-bottom: 44px;
	}

	.featured {
		padding-top: 75px;
	}

	.featured-three {
		padding: 76px 0 80px;
	}

	.featured-homethree {
		padding-top: 0;
	}

	.saaspik-icon-box-wrapper.style-two {
		margin-bottom: 40px;
		text-align: center;
	}

	.featured-four {
		padding-top: 185px;
		padding-bottom: 40px;
	}

	.featured-homethree {
		padding-bottom: 34px;
	}

	.featured-four-ab {
		padding-top: 76px;
	}

	.featured-two-service {
		padding: 55px 0 43px !important;
	}

	.featured-two-same .scroll-circle {
		left: -20%;
	}

	.featured-three {
		.scroll-circle {
			z-index: 1;
			left: -15%;
			bottom: 20%;

			img {
				max-width: 450px;
			}
		}
	}

	.saaspik-icon-box-wrapper {
		&.style-one {
			text-align: center;
		}
	}

	.saaspik-icon-box-wrapper {
		.pixsass-icon-box-content {
			.pixsass-icon-box-title {
				a {
					br {
						display: none;
					}
				}
			}
		}
	}

	.featured-six {
		padding-top: 0;
		padding-bottom: 38px;
	}

	.featured-seven {
		padding: 75px 0 30px;
	}

	.saaspik-icon-box-wrapper {
		&.style-seven {
			margin-bottom: 50px;
		}
	}

	.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content p {
		br {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.saaspik-icon-box-wrapper .pixsass-icon-box-content p {
		br {
			display: none;
		}
	}

	.featured-four {
		padding-top: 250px;
	}

	.featured-two-same .scroll-circle {
		left: -30%;
	}

	.featured-three {
		.scroll-circle {
			z-index: 1;
			left: -15%;
			bottom: 20%;

			img {
				max-width: 330px;
			}
		}
	}

	.feature-image-wrapper {
		margin-bottom: 40px;
	}

	.featured-eight .top-bg {
		background: $color__theme-4;
		min-height: 350px;
	}
}

@media (max-width: 767px) {
	.featured-four {
		padding-top: 137px;
	}
}

@media (max-width: 576px) {
	.featured-four {
		padding-top: 76px;
	}
}

@media (max-width: 480px) {
	.featured-two-same .scroll-circle {
		left: -70%;
	}

	.saaspik-icon-box-wrapper {
		&.style-three {
			text-align: center;
			display: block;

			.saaspik-icon-box-icon {
				margin: 0 auto 30px;
			}
		}
	}
}

#app-feature {
	padding: 88px 0 66px;
	background: #fcfafb;

	@media (max-width: 991px) {
		padding: 60px 0;
	}

	.icon {
		margin-bottom: 29px;
	}
}

.social-icon-box {
	box-shadow: none;
	background: transparent;

	&.app-icon-box {
		box-shadow: none;
		background: transparent;
		padding: 58px 25px 44px;
		border-top: 6px solid transparent;
		border-radius: 0;
		transition: all 0.3s ease-in-out;

		&:hover {
			border-color: $color__theme-9;
			box-shadow: 0px 30px 60px 0px rgba(130, 5, 82, 0.1),
				0px -6px 0px 0px rgba(212, 51, 150, 0.004);

			a {
				opacity: 1;
			}
		}

		i {
			font-size: 20px;
			vertical-align: middle;
			margin-left: 8px;
		}

		a {
			color: #0d2244;
			opacity: 0;

			&:hover {
				color: $color__theme-9;
			}
		}
	}

	&.app-icon-box-two {
		padding: 38px;
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 2px 4px 0px rgba(0, 6, 83, 0.1);
		transition: all 0.3s ease-in-out;
		border-top: 3px solid transparent;

		@media (max-width: 991px) {
			margin-bottom: 30px;
		}

		.icon {
			border-radius: 50%;
			background-color: rgb(67, 51, 101, 0.039);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 80px;
			width: 80px;
			margin-bottom: 30px;
		}

		.view-link {
			display: inline-flex;
			color: #0d2244;
			align-items: center;
			gap: 5px;

			&:hover {
				color: $color__theme-10;
			}
		}

		&:hover {
			box-shadow: 0px -3px 0px 0px rgba(117, 62, 243, 0.004),
				0px 20px 40px 0px rgba(25, 0, 84, 0.1);
			transition: translateY(-5px);
			border-color: $color__theme-10;
		}
	}

	h3 {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 15px;
		color: #0d2244;
		line-height: 1;
	}

	p {
		margin-bottom: 25px;
		color: #606e83;
		line-height: 28px;

		@media (max-width: 1200px) {
			br {
				display: none;
			}
		}
	}
}

#app-feature-two {
	padding: 120px 0;

	@media (max-width: 991px) {
		padding: 80px 0;
	}
}
