.call-to-action {
	background-image: $gradient_background;
	padding: 111px 0 120px;
	overflow: hidden;

	.overlay-bg {
		position: absolute;
		right: 0;
		top: 0;
	}

	.action-content {
		position: relative;

		.title {
			color: #fff;
			font-size: 50px;
			line-height: 64px;
			font-weight: 700;
			margin-bottom: 28px;
		}

		p {
			color: #fff;
			margin-bottom: 50px;
		}

		.pix-btn {
			padding: 12px 50px;
			font-size: 16px;
			font-weight: 500;
			position: relative;
			z-index: 2;
		}

		&.style-two {
			.title {
				font-size: 40px;
				line-height: 54px;
				margin: 0;
			}
		}
	}

	&.action-padding {
		padding: 172px 0 168px;
	}

	.scroll-circle {
		right: 0;
		top: 40%;
	}
}

.signup-section {
	padding: 106px 0 111px;
	background-size: cover;
	background-position: center center;
	position: relative;
	background-image: linear-gradient(
		0deg,
		rgb(79, 41, 221) 0%,
		rgb(112, 82, 251) 100%
	);
	overflow: hidden;

	.bg-shape {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
		}

		.shape-left {
			top: 0;
			left: 0;
		}

		.shape-right {
			bottom: 0;
			right: 0;
		}
	}
}

.signup-heading {
	.title {
		font-size: 50px;
		line-height: 64px;
		color: #fff;
		font-weight: 700;
		margin-bottom: 20px;
	}

	p {
		color: #fff;
		margin: 0;
	}
}

// Call To Action Three
.call-to-action-three {
	background: $color__theme-4;
	padding: 230px 0 130px;
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-top: 0px solid transparent;
		border-bottom: 170px solid transparent;
		border-right: 3000px solid #fff;
	}

	.bg-right-shape {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.action-content {
		.title {
			font-size: 50px;
			line-height: 60px;
			color: #fff;
			margin-bottom: 30px;
		}

		p {
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			margin: 0;
			margin: 0;
		}
	}
}

.call-to-action-four {
	background: #f9f8fc;
	padding: 50px 0;
	border-top: 1px solid #e9e7f1;

	@media (max-width: 768px) {
		.text-right {
			text-align: left !important;
			margin-top: 30px;
		}
	}

	.action-content {
		.title {
			font-size: 26px;
			line-height: 40px;
			font-weight: 600;
		}

		p {
			font-size: 26px;
			line-height: 40px;
			color: #75737e;
			margin: 0;

			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
	}

	.pix-btn {
		padding: 15px 43px;
	}
}

.call-to-action-hosting {
	padding: 130px 0 140px;
	background-image: url("../../media/background/call-to-action.jpg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	.section-title {
		margin-bottom: 38px;
	}

	.btn-outline {
		margin-left: 15px;

		@media (max-width: 380px) {
			margin-left: 0;
			margin-top: 20px;
		}
	}
}

.ai-call-to-action {
	border-radius: 10px;
	background: #131b2e;
	padding-top: 40px;

	.section-title {
		font-family: "Montserrat Alternates";
		font-size: 44px;
		font-weight: 600;
		color: #fff;
		margin-bottom: 50px;

		@media (max-width: 991px) {
			font-size: 32px;
		}
	}

	.download-button-wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 20px;

		.downlod-btn {
			margin-right: 0;
		}

		@media (max-width: 767px) {
			justify-content: center;
		}
	}

	.call-to-action-content {
		padding: 40px;

		@media (max-width: 767px) {
			text-align: center;
		}
	}

	.call-to-action-image {
		@media (max-width: 767px) {
			text-align: center;
		}
	}

	.download-button-wrapper .downlod-btn {
		&:hover {
			background: $color__theme-11;
			color: #000;
		}
	}
}

@media (max-width: 1200px) {
	.call-to-action {
		.action-content {
			&.style-two {
				.title {
					font-size: 36px;
					line-height: 50px;
				}
			}
		}
	}

	.signup-heading .title {
		font-size: 40px;
		line-height: 50px;
	}
}

@media (max-width: 991px) {
	.call-to-action {
		padding: 71px 0 80px;
	}

	.call-to-action {
		&.action-padding {
			padding: 74px 0 80px;
		}
	}

	.call-to-action {
		.action-content {
			.title {
				font-size: 40px;
				line-height: 54px;
			}
		}
	}

	.call-to-action {
		.text-right {
			text-align: center !important;
		}

		.action-content {
			&.style-two {
				margin-bottom: 30px;
				text-align: center;

				.title {
					font-size: 32px;
					line-height: 42px;

					br {
						display: none;
					}
				}
			}
		}
	}

	.call-to-action {
		.scroll-circle {
			max-width: 400px;
		}
	}

	.call-to-action-three {
		padding: 130px 0 100px;

		.action-content {
			.title {
				font-size: 36px;
				line-height: 46px;
				margin-bottom: 20px;
			}

			p {
				font-size: 20px;
			}
		}
	}
}

@media (max-width: 768px) {
	.call-to-action {
		.action-content {
			.title {
				font-size: 32px;
				line-height: 42px;
			}

			p {
				br {
					display: none;
				}
			}
		}
	}

	.call-to-action {
		.scroll-circle {
			max-width: 300px;
		}
	}

	.signup-section {
		.button-container {
			margin-top: 30px;

			&.text-right {
				text-align: left !important;
			}
		}
	}

	.call-to-action-three {
		.text-right {
			margin-top: 20px;
			text-align: left !important;
		}
	}
}

#call-to-action-five {
	position: relative;
	padding: 120px 0 114px;
	background-image: linear-gradient(
		260deg,
		rgb(101, 65, 193) 0%,
		rgb(212, 51, 150) 100%
	);
	overflow: hidden;

	.color-ten {
		padding: 19px 33px;
		border-radius: 30px;
		&:hover {
			color: #fff;
		}
	}
	.action-button {
		margin-top: 10px;
	}
	.background-shape {
		position: absolute;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.031);
		width: 500px;
		height: 500px;
		right: 80px;
		top: 40%;
	}
	.background-shape-wrap {
		position: absolute;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.031);
		width: 500px;
		height: 500px;
		right: 305px;
		top: 70%;
	}

	.action-content {
		.action-title {
			font-size: 40px;
			line-height: 54px;
			font-weight: 700;
			color: #fff;

			@media (max-width: 991px) {
				font-size: 34px;
				line-height: 44px;
			}

			@media (max-width: 768px) {
				margin-bottom: 30px;
			}
		}
	}

	.pix-btn {
		padding: 13px 32px;
		background-color: $color__theme-9;
		border: 2px solid $color__theme-9;
		box-shadow: 0px 20px 30px 0px rgba($color__theme-9, 0.3);

		&:hover {
			background-color: transparent;
			border-color: #fff;
			color: #fff;
			box-shadow: none;
		}
	}

	.call-to-action-two {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 768px) {
			display: block;
			text-align: center;
		}

		.action-content {
			.action-title {
				font-size: 40px;
				line-height: 54px;
				font-weight: 700;
				color: #fff;

				@media (max-width: 991px) {
					font-size: 34px;
					line-height: 44px;
				}

				@media (max-width: 768px) {
					margin-bottom: 30px;
				}
			}
		}

		.gp-btn {
			padding: 15px 32px;
		}
	}

	.footer-app-shape-one {
		position: absolute;
		left: 9%;
		top: -55%;
		border-radius: 50%;
		width: 600px;
		height: 600px;
		background-color: rgba(255, 255, 255, 0.02);
	}
	.footer-app-shape-two {
		position: absolute;
		left: -3%;
		top: -66%;
		border-radius: 50%;
		width: 500px;
		height: 500px;
		background-color: rgba(255, 255, 255, 0.02);
	}
}

.call-to-action-six {
	background-image: -moz-linear-gradient(
		0deg,
		rgb(124, 84, 223) 0%,
		rgb(145, 71, 200) 100%
	);
	background-image: -webkit-linear-gradient(
		0deg,
		rgb(124, 84, 223) 0%,
		rgb(145, 71, 200) 100%
	);
	background-image: linear-gradient(
		0deg,
		rgb(124, 84, 223) 0%,
		rgb(145, 71, 200) 100%
	);
	padding: 120px 0;
	background-image: url(../../media/background/call-to-action-bg.png);
	background-size: cover;
	background-position: center center;

	@media (max-width: 991px) {
		padding: 80px 0;
	}

	h2.call-to-action-title {
		color: #fff;
		font-size: 40px;
	}

	.call-to-action-description {
		color: #fff;
		margin-bottom: 30px;
	}

	.call-to-action-app-image {
		@media (max-width: 991px) {
			text-align: center;
			margin-top: 50px;
		}
	}

	@media (max-width: 991px) {
		.call-to-action-content {
			text-align: center;
		}

		p {
			br {
				display: none;
			}
		}
	}
}

.download-button-wrapper {
	.downlod-btn {
		display: inline-flex;
		color: #080b32;
		padding: 11px 23px;
		background: #fff;
		border-radius: 10px;
		align-items: center;
		gap: 10px;
		margin-right: 10px;

		&:hover {
			background: $color__theme-10;
			color: #fff;
		}
	}
}
